<template>

  <div
    class="app-about-us-intro"
    role="content">

    <h2>INTRODUCTION
    </h2>

    <p>Oceanographic Data Center, Chinese Academy of Sciences (CASODC) was founded in May 2018, aiming to answer the needs for marine scientific and technological innovations, policies and resource management, and oceanic industries and economic development.
    </p>

    <p>Supported by Institute of Oceanology, Chinese Academy of Sciences (IOCAS) and Center for Ocean Mega-Science, Chinese Academy of Sciences (CODC), so far, CASODC has constructed a data resource pool covering a wide range of disciplines, multi-temporal and spatial scales, including data from independent acquisition, international sharing, remote sensing and modeling, etc. It is worth mentioning that we have collected and compiled 63 sets of western pacific subsurface moorings, 29 sets of China coastal floating buoys and 453 voyages investigation, which were all independently acquired by IOCAS and other member institutes in CODC.
    </p>

    <p>Besides, the center has formed a set of efficient, practical and comprehensive data processing method system, involving multi-source data fusion, quality control, analysis and mining, marine artificial intelligence, etc. Also, we produced ocean salinity and temperature gridded product in cooperation with scientists.
    </p>

    <p>In order to better use and analyze these data and products, we have built a time-space interactive visualization system, CASEarth-Ocean, based on multi-source marine scientific data. Currently, the system has 13 functional modules, including global climate change, ENSO forecast, storm surge disaster, coastal green tides and marine ranching monitoring, providing effective support for government decision-making, disaster prevention and marine economic development.
    </p>

    <p>CASODC makes full use of the data aggregation mission given by CODC to integrate and share data with domestic institutes and organizations related to marine research. And with accumulative experience in data quality control, data processing, data analysis, visualization and other supportive service technology, CASODC has formed an operative service system integrating data management, online analysis, visualization and decision support to provide high quality services for marine scientific and technological innovations, policies and resource management, and oceanic industries and economic development.
    </p>

    <p>Approved:
    </p>

    <p>Marine Science Data Center, Chinese Academy of Sciences (2020)
    </p>

    <p>The first batch of excellent Institute level centers of scientific data center system of Chinese Academy of Sciences (2019)
    </p>

    <p>The first batch of excellent Institute level centers of scientific data center system of Chinese Academy of Sciences (2019)
    </p>

    <p>Shandong Marine Information Intelligent Application Engineering Technology Collaborative Innovation Center (2019)
    </p>

    <p>Iqod China node (2018)
    </p>

    <p>Qingdao sub center of high performance computing environment, Chinese Academy of Sciences (2009)
    </p>

  </div>

</template>

<script>
export default {
  name: 'app-about-us-intro'
}
</script>

<style lang="scss" scoped>
.app-about-us-intro {
  background: white;
  box-sizing: border-box;
  margin: {
    bottom: 50px;
    top: 50px;
  };
  padding: {
    bottom: 20px;
    left: 20px;
    right: 20px;
    top: 20px;
  };

  p {
    text-align: left;
    text-indent: 4ch;
  }
}
</style>>
